import styles from "./LicenseAgreementButtons.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import Icon from "@/icon/Icon";
import { IconNames } from "@/types/iconNames";
import {
  getAcceptLicense,
  getDownloadActive,
  getEmail,
  getLastTraceId,
  getSelectedSoftwareCube1,
  getSelectedSoftwareCube2,
  getTraceIdList,
  getWaitingStatus,
} from "@/utils/selectors";
import { useGetGenerateSDKQuery } from "@/pages/api/apiSlice";
import {
  createSoftwareListIds,
  downloadFile,
  startSubscribe,
} from "@/utils/function";
import {
  addFileName,
  toggleDownload,
  toggleWaitingDialog,
} from "@/features/download/downloadSlice";
import { ErrorBanner } from "@/components/errorBanner/ErrorBanner";
import { WaitingDialog } from "@/components/waitingDialog/WaitingDialog";
import { labels } from "@/utils/labels";
import { Button } from "@/components/button/Button";
import { LoginDialog } from "@/components/loginDialog/LoginDialog";
import { resetAllSoftware } from "@/features/software/softwareSlice";
import { addTraceId, removeTraceId } from "@/features/traceId/traceIdSlice";
import { setAcceptLicense } from "@/features/license/licenseSlice";
import { ButtonType } from "@/types/types";
import { SDK } from "@/types/aws";
import { Dialog, DialogType } from "@/components/dialog/Dialog";

export const LicenseAgreementButtons = () => {
  const dispatch = useDispatch();
  const userEmail = useSelector(getEmail);
  const selectedSoftwareCube1 = useSelector(getSelectedSoftwareCube1);
  const selectedSoftwareCube2 = useSelector(getSelectedSoftwareCube2);
  const downloadActive = useSelector(getDownloadActive);
  const lastTraceId = useSelector(getLastTraceId);
  const acceptLicense = useSelector(getAcceptLicense);
  const waitingStatus = useSelector(getWaitingStatus);
  const traceIdsList = useSelector(getTraceIdList);

  const [openLoginDialog, setOpenLoginDialog] = useState<boolean>(false);
  const [clickedItem, setClickedItem] = useState<SDK | null>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { currentData: generateSDKData, error: generateSDKError } =
    useGetGenerateSDKQuery(
      {
        softwareList: createSoftwareListIds(
          selectedSoftwareCube1,
          selectedSoftwareCube2
        ),
      },
      {
        skip: !downloadActive,
      }
    );

  /**
   * @description When user come back from login page, the sdkGeneration can start thanks to the value change
   * of downloadActive state - manage from toggleDownload action
   */
  useEffect(() => {
    if (userEmail && acceptLicense) {
      dispatch(toggleDownload(true));
    }
  }, [userEmail, acceptLicense]);

  /**
   * @description If generateSdk response code is 200:
   * start subription to aws amplify with the list of traceIds
   */
  useEffect(() => {
    if (generateSDKData?.sdkGuidReference && !generateSDKError) {
      startSubscribe(traceIdsList, dispatch);
      dispatch(toggleWaitingDialog(true));
    }
    if (generateSDKData && generateSDKData.filename) {
      dispatch(addFileName(generateSDKData.filename));
    }
  }, [generateSDKData]);

  /**
   * Handle click on I Accept button.
   *
   * @description when user click on this button we set some global states:
   * aceptLicense become true
   * add the lastTraceId gnerated in FirstStep, inside downloadQuery state
   * if the user is not yet logged in, oblige him to do
   */
  const handleClick = () => {
    dispatch(setAcceptLicense(true));
    dispatch(addTraceId(lastTraceId));

    if (!userEmail) {
      setOpenLoginDialog(true);
    }
  };

  const getContent = () => {
    if (generateSDKError) {
      if ("data" in generateSDKError) {
        const errorDetails = generateSDKError.data as {
          errorCode: number;
          errorDescription: string;
        };

        if (errorDetails.errorCode !== 408) {
          return (
            <ErrorBanner isVisible={true} errorCode={errorDetails.errorCode}>
              {errorDetails.errorDescription}
            </ErrorBanner>
          );
        }

        if (errorDetails.errorCode === 408) {
          return <WaitingDialog open={waitingStatus} />;
        }
      }
    } else {
      return (
        <WaitingDialog
          open={waitingStatus}
          percentage={clickedItem?.percentage?.toString()}
        />
      );
    }
  };

  return (
    <>
      {getContent()}
      <section className={styles.container}>
        <button type="button" className={styles.downloadBtn}>
          <span className={styles.downloadIcon}>
            <Icon
              iconName={IconNames.DOWNLOAD}
              color={"var(--color-secondary)"}
            />
          </span>
          <strong
            className={styles.downloadText}
            onClick={() =>
              downloadFile("licensepdf/1", "SDK_software_license_agreement.pdf")
            }
          >
            {labels.DOWNLOAD_PDF}
          </strong>
        </button>
        <div className={styles.acceptBtnBox}>
          <button
            type="button"
            className={styles.dontBtn}
            onClick={() => setIsOpen(true)}
          >
            {labels.NO_ACCEPT}
          </button>
          <Button
            buttonText={labels.ACCEPT}
            onClick={() => handleClick()}
          ></Button>
        </div>
      </section>
      <LoginDialog
        open={openLoginDialog}
        onOpenChange={() => setOpenLoginDialog(false)}
        hasCancelBtn
      />
      <Dialog
        type={DialogType.GENERAL_SDK}
        open={isOpen}
        onOpenChange={setIsOpen}
        title={labels.LICENCE_REFUSAL}
      >
        <>
          <div className={styles.textWrapper}>
            <p className={styles.paragraph}>{labels.NO_LICENSE_ACCEPT_MODAL}</p>
          </div>
          <div className={styles.footer}>
            <Button
              buttonText="Cancel"
              onClick={() => setIsOpen(false)}
              type={ButtonType.LINK}
            ></Button>
            <Button
              buttonText={labels.NO_ACCEPT}
              onClick={() => {
                dispatch(resetAllSoftware());
                dispatch(removeTraceId(lastTraceId));
              }}
              type={ButtonType.PRIMARY}
            ></Button>
          </div>
        </>
      </Dialog>
    </>
  );
};
