import styles from "./LoginDialog.module.scss";
import Link from "next/link";
import Icon from "@/icon/Icon";
import { Dialog, DialogProps } from "@/components/dialog/Dialog";
import { IconNames } from "@/types/iconNames";
import { labels } from "@/utils/labels";
import { cognitoUrlLogin } from "@/utils/constant";

export type LoginDialogProps = Omit<DialogProps, "children"> & {
  myProps?: boolean;
};

export const LoginDialog = ({ myProps, ...props }: LoginDialogProps) => {
  return (
    <Dialog title="Please Login to get the SDK" {...props}>
      <div className={styles.container}>
        <Link className={styles.link} href={cognitoUrlLogin}>
          <span className={styles.iconContainer}>
            <Icon iconName={IconNames.EXIT} color={"var(--color-neutral-10)"} />
          </span>
          {labels.LOGIN_MY_ST}
          <span className={styles.iconContainer}>
            <Icon
              iconName={IconNames.ARROW_RIGHT}
              color={"var(--color-primary)"}
            />
          </span>
        </Link>
      </div>
    </Dialog>
  );
};
